body {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #293347 !important;
}


.theme-purple {
  color: #9f30fa !important;
}
.theme-blue {
  color: #60b8f7 !important;
}

.logo {
  max-width: 25%;
  height: auto;
}

h5 {
  color: #a0a09e;
  font-weight: 200 !important;
  font-size: 1.75rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-fluid {
  padding: 25px;
  margin: 0px 10px;
}

.btn-large i {
  font-size: 3em !important;
}
.btn-large {
  -webkit-box-shadow: 0px 0px 0px 0px !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.nav-btn,
a {
  color: #60b8f7 !important;
  margin: auto 0px;
  cursor: pointer;
  background-color: transparent !important;
  text-decoration: none;
}
.nav-btn:hover,
a:hover {
  text-decoration: none;
  color: #9f30fa !important;
  background-color: transparent !important;
  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.blue-wm {
  color: #60b8f7 !important;
  margin: auto 0px;
  cursor: pointer;
  background-color: transparent !important;
}

.b-r {
  border-right: #9f30fa 1px solid;
}

.roles-container {
  margin-top: -25px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #e6ecf8;
}

p {
  font-size: 1.2rem;
}

.purple-text-wm {
  color: #9f30fa !important;
}

.blue-gradient {
  font-size: 2.8rem;
  font-weight: 900;
  background: -webkit-linear-gradient(#9f30fa, #60b8f7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Playfair Display", serif;
  font-style: italic;
}
.red-gradient {
  font-size: 2.8rem;
  font-weight: 900;
  font-style: italic;
  background: -webkit-linear-gradient(#fffb07, #f44336);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Playfair Display", serif;
}

.playfair {
  font-family: "Playfair Display", serif !important;
  font-weight: 700 !important;
}

.slantedDivBlue {
  position: relative;
  background: rgba(96, 184, 287, 0.8);
  color: white;
}

.footer {
  background: -webkit-linear-gradient(#9f30fa, #60b8f7);
}

.wm-icon {
  vertical-align: top;
}

.slantedDivBlue::after {
  position: absolute;
  background: rgb(159, 48, 250);
  width: 100%;
  height: 100%;
  z-index: -1;
  transform-origin: top right;
  content: "";
  transform: skewY(-5deg);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.theme-orange {
  color: #f37023;
}
.emphasize {
  font-weight: 700;
  font-size: 3rem;
}
.theme-red {
  color: #ee3923;
}

.profile-pic {
  background-image: url(./Images/ProfilePic.png);
  border-radius: 50%;
  border: 2px solid #293347;
  background-repeat: none;
  background-size: cover;
  height: 250px;
  width: 250px;
}

.start-chat {
  margin-top: -50px;
  background: #293347;
  border-radius: 10px;
  border: 1px #293347 solid;
}
.bio {
  background: #293347;
  border-radius: 10px;
  border: 1px #293347 solid;
}

.btn-footer {
  border-radius: 30px;
  border: 2px solid;
}

.theme-white {
  color: #fff;
}

.cls-4,
.cls-10,
.cls-32 {
  stroke: #293347;
  fill: #293347;
  stroke-dasharray: 650;
  animation: animateMedium 3s cubic-bezier(0, 0.23, 1, 0.1);
}

@keyframes animateMedium {
  0% {
    stroke-dashoffset: 650;
    fill: none;
  }
  30% {
    stroke-dashoffset: 650;
    fill: none;
  }
  90% {
    fill: #293347;
  }
  100% {
    fill: #293347;
    opacity: 10;
    stroke-dashoffset: 0;
  }
}

.cls-1,
.cls-2,
.cls-3,
.cls-22,
.cls-23,
.cls-24,
.cls-25,
.cls-26,
.cls-27,
.cls-28 {
  stroke: #293347;
  fill: #293347;
  stroke-dasharray: 40;
  animation: animateXSmall 3s cubic-bezier(0, 0.23, 1, 0.1);
}

@keyframes animateXSmall {
  0% {
    stroke-dashoffset: 40;
    fill: none;
  }
  30% {
    stroke-dashoffset: 40;
    fill: none;
  }
  90% {
    fill: #293347;
  }
  100% {
    fill: #293347;
    opacity: 10;
    stroke-dashoffset: 0;
  }
}
.cls-18,
.cls-19,
.cls-5,
.cls-6,
.cls-8,
.cls-7,
.cls-9,
.cls-20,
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-30,
.cls-31,
.cls-21 {
  stroke: #293347;
  fill: #293347;
  stroke-dasharray: 230;
  animation: animateSmall 3s cubic-bezier(0, 0.23, 1, 0.1);
}

@keyframes animateSmall {
  0% {
    stroke-dashoffset: 230;
    fill: none;
  }
  30% {
    stroke-dashoffset: 230;
    fill: none;
  }
  90% {
    fill: #293347;
  }
  100% {
    fill: #293347;
    opacity: 10;
    stroke-dashoffset: 0;
  }
}
.cls-17,
.cls-29 {
  stroke: #293347;
  fill: #293347;
  stroke-dasharray: 2200;
  animation: animateLarge 3s cubic-bezier(0, 0.23, 1, 0.1);
}

@keyframes animateLarge {
  0% {
    stroke-dashoffset: 2200;
    fill: none;
  }
  30% {
    stroke-dashoffset: 2200;
    fill: none;
  }
  90% {
    fill: #293347;
  }
  100% {
    fill: #293347;
    opacity: 10;
    stroke-dashoffset: 0;
  }
}

.zoom {
  transition: transform .2s; /* Animation */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
